<template>
     <div class="Client">
        <!-- BEGIN: Content-->
        <div class="app-content content ">
            <div class="content-overlay"></div>
            <div class="header-navbar-shadow"></div>
            <div class="content-wrapper container-xxl p-0">

                <banner title="Income Tax Return Filing" :breadcrumb="[
                    { label: 'individual ', url: 'http://localhost:8080/retailer/client' },
                    { label: 'Other Informations '},
                   
                ]">
                </banner>


                <div class="row " style="margin-top: -45px;">
                     <div class="col-12 col-md-6 col-lg-6" style="padding-right: 5px;">
                                <div class="card">
                                    <div class="card-body">
                                        <h3>Step-4 : Other Informations</h3>
                                        <div class="progress progress-bar-danger">
                                            <div class="progress-bar" role="progressbar" aria-valuenow="100"
                                                aria-valuemin="100" aria-valuemax="100" style="width: 100%">100%</div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-xl-6 col-md-6 col-12" style="padding:5px 5px 5px 5px;">
                                                <div class="form-group">
                                                    <select v-model="form.state_id" name="state_id" class="form-control"
                                                        :class="{
                                                            'is-invalid': form.errors.has('state_id'),
                                                        }">
                                                        <option v-if="!form.state_id" selected value="">
                                                            Select State
                                                        </option>
                                                        <option :value="state.id" v-for="state in states"
                                                            :key="state.id">
                                                            {{ state.name }}
                                                        </option>
                                                    </select>
                                                    <has-error :form="form" field="name"></has-error>
                                                </div>
                                            </div>
                                            <div class="col-xl-6 col-md-6 col-12" style="padding:5px 5px 5px 5px;">
                                               <div class="form-group">
                                                    <input type="text" class="form-control" v-model="form.city"
                                                        placeholder="City*" />
                                                </div>
                                            </div>
                                            <div class="col-xl-12 col-md-12 col-12" style="padding:5px 5px 5px 5px;">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" v-model="form.address"
                                                        placeholder="Address of Firm-1 " />
                                                </div>
                                            </div>
                                            <div class="col-xl-10 col-md-12 col-12" style="padding:5px 5px 5px 5px;">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" v-model="form.address2"
                                                        placeholder="Address of Firm-2" />
                                                </div>
                                            </div>
                                            <div class="col-xl-2 col-md-12 col-12" style="padding:5px 5px 5px 5px;">
                                                <div class="form-group">
                                                    <input type="number" v-model="form.businessPin" class="form-control"
                                                        placeholder="Zip Code*" />
                                                </div>
                                            </div>
                                        </div>

                                        <br>
                                        <div class="row">
                                            <div class="col-6 p-1 text-start" style="padding:5px 5px 5px 5px;">
                                                    <button type="button" class="btn-sm btn text-white" style="background-color:#00364f;width:50%;">
                                                        <strong>Back</strong>
                                                    </button>

                                            </div>
                                            <div class="col-6 p-1 text-end" style="padding:5px 5px 5px 5px;">
                                                <button type="button"  @click="saveinfo" class="btn btn-sm text-white" style="background-color:#f21300;width:50%;">
                                                   <strong> save </strong>
                                                </button>
                                                
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12  col-md-6  col-lg-6">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <h2 class="heading">FAQ's</h2>
                                                <div class="accordion accordion-margin accordion-border"
                                                    id="accordionMargin">
                                                    <div class="accordion-item">
                                                        <h2 class="accordion-header" id="headingMarginOne">
                                                            <button class="accordion-button collapsed" type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#accordionMarginOne"
                                                                aria-expanded="false"
                                                                aria-controls="accordionMarginOne">
                                                                <i data-feather='plus' class="accordian-plus"></i> What
                                                                is Salary Income?
                                                            </button>
                                                        </h2>
                                                        <div id="accordionMarginOne" class="accordion-collapse collapse"
                                                            aria-labelledby="headingMarginOne"
                                                            data-bs-parent="#accordionMargin">
                                                            <div class="accordion-body">
                                                                Pastry pudding cookie toffee bonbon jujubes jujubes
                                                                powder topping. Jelly beans gummi bears sweet roll
                                                                bonbon muffin liquorice. Wafer lollipop sesame snaps.
                                                                Brownie macaroon cookie muffin cupcake candy
                                                                caramels tiramisu. Oat cake chocolate cake sweet jelly-o
                                                                brownie biscuit marzipan. Jujubes donut
                                                                marzipan chocolate bar. Jujubes sugar plum jelly beans
                                                                tiramisu icing cheesecake.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="accordion-item">
                                                        <h2 class="accordion-header" id="headingMarginTwo">
                                                            <button class="accordion-button collapsed" type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#accordionMarginTwo"
                                                                aria-expanded="false"
                                                                aria-controls="accordionMarginTwo">
                                                                <i data-feather='plus' class="accordian-plus"></i> What
                                                                is Capital Gain?
                                                            </button>
                                                        </h2>
                                                        <div id="accordionMarginTwo" class="accordion-collapse collapse"
                                                            aria-labelledby="headingMarginTwo"
                                                            data-bs-parent="#accordionMargin">
                                                            <div class="accordion-body">
                                                                Sweet pie candy jelly. Sesame snaps biscuit sugar plum.
                                                                Sweet roll topping fruitcake. Caramels
                                                                liquorice biscuit ice cream fruitcake cotton candy tart.
                                                                Donut caramels gingerbread jelly-o
                                                                gingerbread pudding. Gummi bears pastry marshmallow
                                                                candy canes pie. Pie apple pie carrot cake.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="accordion-item">
                                                        <h2 class="accordion-header" id="headingMarginThree">
                                                            <button class="accordion-button collapsed" type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#accordionMarginThree"
                                                                aria-expanded="false"
                                                                aria-controls="accordionMarginThree">
                                                                <i data-feather='plus' class="accordian-plus"></i> What
                                                                is House Property Income?
                                                            </button>
                                                        </h2>
                                                        <div id="accordionMarginThree"
                                                            class="accordion-collapse collapse"
                                                            aria-labelledby="headingMarginThree"
                                                            data-bs-parent="#accordionMargin">
                                                            <div class="accordion-body">
                                                                Tart gummies dragée lollipop fruitcake pastry oat cake.
                                                                Cookie jelly jelly macaroon icing jelly beans
                                                                soufflé cake sweet. Macaroon sesame snaps cheesecake
                                                                tart cake sugar plum. Dessert jelly-o sweet
                                                                muffin chocolate candy pie tootsie roll marzipan. Carrot
                                                                cake marshmallow pastry. Bonbon biscuit
                                                                pastry topping toffee dessert gummies. Topping apple pie
                                                                pie croissant cotton candy dessert tiramisu.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="accordion-item">
                                                        <h2 class="accordion-header" id="headingMarginFour">
                                                            <button class="accordion-button collapsed" type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#accordionMarginFour"
                                                                aria-expanded="false"
                                                                aria-controls="accordionMarginFour">
                                                                <i data-feather='plus' class="accordian-plus"></i> What
                                                                is Business Income?
                                                            </button>
                                                        </h2>
                                                        <div id="accordionMarginFour"
                                                            class="accordion-collapse collapse"
                                                            aria-labelledby="headingMarginFour"
                                                            data-bs-parent="#accordionMargin">
                                                            <div class="accordion-body">
                                                                Cheesecake muffin cupcake dragée lemon drops tiramisu
                                                                cake gummies chocolate cake. Marshmallow tart
                                                                croissant. Tart dessert tiramisu marzipan lollipop lemon
                                                                drops. Cake bonbon bonbon gummi bears topping
                                                                jelly beans brownie jujubes muffin. Donut croissant
                                                                jelly-o cake marzipan. Liquorice marzipan cookie
                                                                wafer tootsie roll. Tootsie roll sweet cupcake.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="accordion-item">
                                                        <h2 class="accordion-header" id="headingMarginFive">
                                                            <button class="accordion-button collapsed" type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#accordionMarginFive"
                                                                aria-expanded="false"
                                                                aria-controls="accordionMarginFive">
                                                                <i data-feather='plus' class="accordian-plus"></i> What
                                                                are Other Sources of Income?
                                                            </button>
                                                        </h2>
                                                        <div id="accordionMarginFive"
                                                            class="accordion-collapse collapse"
                                                            aria-labelledby="headingMarginFive"
                                                            data-bs-parent="#accordionMargin">
                                                            <div class="accordion-body">
                                                                Cheesecake muffin cupcake dragée lemon drops tiramisu
                                                                cake gummies chocolate cake. Marshmallow tart
                                                                croissant. Tart dessert tiramisu marzipan lollipop lemon
                                                                drops. Cake bonbon bonbon gummi bears topping
                                                                jelly beans brownie jujubes muffin. Donut croissant
                                                                jelly-o cake marzipan. Liquorice marzipan cookie
                                                                wafer tootsie roll. Tootsie roll sweet cupcake.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                </div>


            </div>

        </div>
    </div>
</template>

<script>
      import Banner from '../../../../components/retailer/comman/Banner.vue'
import Form from 'vform'
export default {
    name: 'Itr4',
    components: {
        Banner,
    },
    data() {
        return {
            // termCondition: 'false1'
            states: [],
            state: {},
            form: new Form({
                state_id: '',
                city: '',
                address: '',
                address2: '',
                pin : null
            }),
        }
    },
    methods: {
        getState() {
            this.$axios
                .get('retailer/state?all=all', { headers: { Authorization: 'Bearer ' + localStorage.accessToken } })
                .then(res => {
                    this.states = res.data.data
                })
                .catch((error) => {
                    error = error.data.message
                    console.log(error)
                })
        },
        saveinfo() {
            var itr = {}
            itr = JSON.parse(localStorage.getItem('itr'))
            
             itr.state_id = this.form.state_id
            itr.city = this.form.city
            itr.address = this.form.address
            itr.address2 = this.form.address2
            itr.pin = this.form.pin
            // if(itr.client_id === null || itr.client_id === '') {
            localStorage.setItem('itr', JSON.stringify(itr))
            this.$axios
                .post('retailer/itrfilling', itr, { headers: { Authorization: 'Bearer ' + localStorage.accessToken } })
                .then(res => {
                    // console.log(res.data.clientService.id)
                    alert(res.data.clientService.id)
                    this.$swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'The form was sent successfully.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.$router.push({ name: 'Itr5', params: { id: res.data.clientService.id } })
                })
            // } else {

            // }
            // localStorage.setItem('itr', JSON.stringify(itr))
            // this.$router.push({name: 'Itr4'})

        }
    },
    created() {
        if (this.$route.meta.redirectIfLoggedIn == 'true' && localStorage.getItem('accessToken') == null) {
            this.$router.push({ name: 'Login' })
        } else {
            this.getState()
            var itr = {}
            itr = JSON.parse(localStorage.getItem('itr'))
            if (itr.state_id) {
                this.form.state_id = itr.state_id
                this.form.city = itr.city
                this.form.address = itr.address
                this.form.address2 = itr.address2
                this.form.pin = itr.pin
            }
        }
    },
}
</script>

<style scoped>
.accordian-plus {
    width: 20px;
    height: 20px;
    stroke: red;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
}

.bg-image {
    background-color: #fff;
    background-image: url(https://apk.mudhrape.com/wp-content/uploads/2022/01/background.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 15px 0 120px;
    /* text-align: center; */
    color: white;
    /* background-size: 100% 100%;
    width: 100%; */
}

.h2-heading {
    color: white;
    font-family: poppins, Sans-serif;
    font-size: 40px;
    font-weight: 600;
    text-align: center;
}

@media (max-width: 767px) {
    .h2-heading {
        font-size: 22px;
    }
}

.heading {
    color: #00364f;
    font-family: poppins, Sans-serif;
    font-weight: 600;
    margin-bottom: 20px;
    padding: 20px 0;
}
</style>